import React, {useContext, useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import {ApiContext} from "../services/api";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/cjs/Image";

const EntryDiv = styled.div`
    margin-top: 25px;
    cursor: pointer;
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.primaryColor};
    opacity: .85;
    border-radius: 10px;
    padding: 20px;
`;

const Subtitle = styled.h3`
    font-size: 19px;
`;

const CreatedSpan = styled.span`
    color: gray;
    font-size: 16px;
    font-style: italic;
    margin-top: 0;
`;

const ListEl = styled.div`
    list-style-type: none;
`;

const PostSummary = withRouter((props) => {
    const data = props.data;
    const header_image = data.header_image || {url: ""}
    const thunbnail = () => {
        if (header_image.url) {
            return (
                <Col xs lg="4">
                    <Image src={header_image.url} alt={"Thumbnail"} thumbnail/>
                </Col>
            )
        }
        return "";
    };
    return (
        <Row onClick={() => {
            props.history.push(`/blog/post/${data.slug}`)
        }}>
            <Card bg='Primary' text='white' className='w-100' as={EntryDiv}>
                <Card.Body>
                    <Row className="justify-content-md-center">
                        {thunbnail()}
                        <Col>
                            <Card.Title as="h2">{data.title}</Card.Title>
                            <Card.Text>
                                <Subtitle>{data.tagline}</Subtitle>
                                <CreatedSpan>Created {new Date(data.timestamp).toDateString()}</CreatedSpan>
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
    );
});


const PostIndex = (props) => {
    const drafts = props.drafts;
    const [posts, setPosts] = useState([]);
    const api = useContext(ApiContext);
    useEffect(() => {
        api.get("/blog/post" + (drafts ? "?drafts=1" : "")).then((response) => {
            setPosts(response && response.data && response.data.posts)
        });
    }, [api, drafts]);
    const postEls = posts.map((data, i) => (
        <ListEl key={data.title}>
            <PostSummary data={data}/>
            {i === posts.length - 1 ? "" : <hr/>}
        </ListEl>
    ));
    return posts ? <>{postEls}</> : <Spinner/>;
};

export {PostSummary};
export default PostIndex;
