import React, {Fragment, useContext, useEffect, useState} from "react";
import {ApiContext} from "../services/api";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Comment = (props) => {
    const slug = props.slug;
    const api = useContext(ApiContext);
    const auth = api.auth[0];
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    useEffect(() => {
        api.get(`/blog/comment/${slug}`)
            .then((response) => setComments(response.data.comments));
    }, [slug, api]);

    const addComment = (event) => {
        event.preventDefault();
        api.post(`/blog/comment/${slug}`, JSON.stringify({text: newComment}), true).then((response) => {
            setNewComment("");
            setComments(response.data.comments);
        })
    };

    const commentEls = comments.length ? comments.map((comment, i) => {
        return (
            <li key={i}>
                <div>{comment.username}</div>
                <div>{comment.text}</div>
                {i === comments.length - 1 ? "" : <hr/>}
            </li>
        )
    }) : (
        <li>
            No comments yet!
        </li>
    );

    const formEl = auth ? (
        <Form className="form-horizontal">
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Comment</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" defaultValue={newComment}
                                  onChange={(event) => setNewComment(event.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{span: 4, offset: 2}}>
                    <Button variant="primary" type="submit" block onClick={addComment}>Submit</Button>
                </Col>
            </Form.Group>
        </Form>
    ) : <h3>Sign Up and Log In to comment</h3>;

    return (
        <Fragment>
            <ul>{commentEls}</ul>
            {formEl}
        </Fragment>
    );
};

export default Comment;
