import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Menu from "./Navbar";
import AddTheme from "../Theme";
import {MessageProvider} from "../services/messages";
import {ApiProvider} from "../services/api";
import {PostContainer} from "../posts/PostContainer";
import {Login} from "../login/Login";
import {Signup} from "../login/Signup"
import About from "./About";
import styled from "styled-components";
import {EditorDemo} from "../editor/Demo";

const PaddedRouter = styled.div`
  padding-top: 56px;
`;

const BaseApp = () => {
    return (
        <PaddedRouter>
            <Router>
                <MessageProvider>
                    <ApiProvider>
                        <Menu/>
                        <Route exact path="/" render={() => <PostContainer/>}/>
                        <Route exact path="/blog/edit" render={() => <PostContainer drafts/>}/>
                        <Route exact path="/blog/post/:slug"
                               render={(props) => <PostContainer slug={props.match.params.slug}/>}/>
                        <Route exact path="/blog/edit/:slug"
                               render={(props) => <PostContainer edit slug={props.match.params.slug}/>}/>
                        <Route exact path="/blog/new/" render={() => <PostContainer edit/>}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/signup" component={Signup}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/editordemo" component={EditorDemo} />
                    </ApiProvider>
                </MessageProvider>
            </Router>
        </PaddedRouter>
    );
};

export const App = AddTheme(BaseApp);
