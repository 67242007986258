import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React from "react";
import Card from "react-bootstrap/Card";
import Table from 'react-bootstrap/Table';
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const projects = [
    {
        name: "Devblog",
        description: "This site. It includes a Flask backend using Postgres + Sqlalchemy " +
            "and a React frontend. The full source code is available on Gitlab if you need " +
            "examples of working code or are just curious. Is built/tested/deployed with a " +
            "full Bazel buildout for the Python, Javascript, and Terraform components. This " +
            "is deployed on Kubernetes hosted on DigitalOcean",
        link: "https://gitlab.com/devblog/devblog",
        demo: "/editordemo",
    },
    {
        name: "Rye",
        description: "A fully configurable Python test/task runner, without coding. Leverages" +
            " pyproject.toml and allows you to specify tasks and environments for those tasks. " +
            "The tool handles ensuring that virtualenvs are properly configured and tasks are " +
            "run in parallel. Compare to Tox.",
        link: "https://rye.readthedocs.io",
    },
    {
        name: "SettingsCascade",
        description: "Parse config files with all the power of CSS. Lets you layer config from " +
            "multiple locations, using inheritance and composition to simplify the process for " +
            "your users to configure your application, while keeping the code in the app itself " +
            "much more simple.",
        link: "https://settingscascade.readthedocs.io",
    },
    {
        name: "Contractual",
        description: "Pact compatible native Python contract testing library (DEVELOPMENT). The " +
            "goal here is to build a library that can test Python objects natively (instead of " +
            "requiring the external http server)- while still creating and verifying contract files " +
            "for downstream systems. Can even be useful within a project to verify complicated mocks." +
            "This is under heavy development.",
        link: "https://gitlab.com/pjbecotte/contractual",
    },
    {
        name: "Digitalocean Volume Plugin",
        description: "Docker volume plugin for digital ocean block volumes",
        link: "https://gitlab.com/pjbecotte/digitalocean_volume_plugin",
    }
];

const AboutContainer = styled(Container)`
    padding: 10px;
    margin-top: 25px;
    border-radius: 15px;
`;

export default () => {
    return (
        <AboutContainer>
            <Row className="mb-2">
                <Card>
                    <Card.Header as="h1">Hi, I'm Paul Becotte!</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>
                            I am an obsessive technologist who lives to build distributed systems
                            that are fault tolerant, can scale horizontally, and provide easy
                            operations and development. I prefer to build something and try it out
                            versus imagining I can perfect it in a vacuum. I believe that
                            investments in work processes have an immediate positive return on
                            investment. I am at my best when solving new problems that require
                            integrating a bunch of tools that were never meant to work that way.
                            </p><p>
                            My career so far has included creating DevOps at a hardware company
                            that didn't believe it was possible, going from developer to CTO at
                            an ad-tech startup, and working on automation, tooling, and the Cloud
                            at a several financial companies. I haven’t yet met the problem that
                            didn’t interest me.
                            </p><p>
                            I have extensive expertise in Python application development, especially
                            testing and SqlAlchemy. I am also an expert in building on the Cloud
                            using Terraform and configuration as code. Finally, I can describe
                            myself as an expert on Kubernetes, both using it and deploying apps on
                            it and setting it up and administering it. I have lesser but functional
                            experience with Javascript in both React and Angular variants, data
                            pipelines and storage, and more traditional sysadmin tasks.
                            </p>
                        </Card.Text>
                        <Button variant="primary" size="lg" block href={`${process.env.PUBLIC_URL}/PaulBecotte.pdf`}>
                            Resume
                        </Button>
                    </Card.Body>
                </Card>
            </Row>
            <Row>
                <Card>
                    <Card.Header as="h1">Projects</Card.Header>
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Link</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project) => {
                                    return (
                                        <tr>
                                            <td>{project.name}</td>
                                            <td><Button variant="primary" block href={project.link}>Link</Button></td>
                                            <td>{project.description}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Card.Body>

                </Card>
            </Row>
        </AboutContainer>
    )
}
