import React, {Fragment, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {AtomicBlockUtils, EditorState} from "draft-js";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const SizedImage = styled.img`
  max-width: ${props => props.size}px;
`;

const Image = (props) => {
    const [show, setShow] = useState(false);
    const entity = props.contentState.getEntity(
        props.block.getEntityAt(0)
    );
    const key = props.block.getKey();
    const currentImage = entity.getData();
    const updateImage = props.blockProps && props.blockProps.updateImage;
    const uploadImage = props.blockProps && props.blockProps.uploadImage;
    const onClick = () => {
        if (updateImage) {
            props.blockProps.setReadOnly(true);
            setShow(true);
        }
    };
    const dismiss = () => {
        setShow(false);
        props.blockProps && props.blockProps.setReadOnly(false);
    };
    return (
        <Fragment>
            {show ? <ImageModal insertImage={(imageData) => updateImage(key, imageData)} dismiss={dismiss}
                                uploadImage={uploadImage} currentImage={{...currentImage}}/> : ""}
            <SizedImage onClick={onClick} size={currentImage.size} src={currentImage.url} alt=""/>
        </Fragment>
    );
};

export const mediaBlockRenderer = (updateImage, uploadImage, setReadOnly) => (block) => {
    if (block.getType() === 'atomic') {
        return {
            component: Image,
            editable: false,
            props: {updateImage: updateImage, uploadImage: uploadImage, setReadOnly: setReadOnly},
        };
    }
    return null;
};

const ImageModal = (props) => {
    const {uploadImage, insertImage, dismiss, currentImage} = props;
    const [imageData, setImageData] = useState(currentImage);
    const handleClose = () => dismiss();
    const handleSave = () => {
        dismiss();
        setTimeout(() => insertImage(imageData));
    };
    const inputFile = useRef(null);
    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload an Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row}>
                        <input
                            id="myInput"
                            type="file"
                            ref={inputFile}
                            style={{display: 'none'}}
                            onChange={(event) => {
                                uploadImage(event.target.files[0])
                                    .then((image) => setImageData({...imageData, ...{url: image.url}}))
                            }}
                        />
                        <Col sm={10}>
                            <Button variant="primary" type="button" block onClick={() => inputFile.current.click()}>
                                Select An Image
                            </Button>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label column sm={2}>Size</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" defaultValue={imageData.size}
                                          onChange={(event) => setImageData({...imageData, ...{size: event.target.value}})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label column sm={2}>Url</Form.Label>
                        <Col sm={10}>
                            <Form.Control type="text" defaultValue={imageData.url}
                                          onChange={(event) => setImageData({...imageData, ...{url: event.target.value}})}/>
                        </Col>
                    </Form.Group>
                    <SizedImage size={imageData.size} src={imageData.url} alt=""/>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
};


export const AddImage = (props) => {
    const {editorState, setEditorState, uploadImage} = props;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const insertImage = (imageData) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            "uploaded-image",
            'IMMUTABLE',
            {url: imageData.url, size: imageData.size},
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithEntity});
        setEditorState(AtomicBlockUtils.insertAtomicBlock(
            newEditorState,
            entityKey,
            ' '
        ));
    };
    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Image
            </Button>
            {show ? <ImageModal
                insertImage={insertImage}
                uploadImage={uploadImage}
                dismiss={() => setShow(false)}
                currentImage={{url: "", size: 200}}/> : ""}
        </Fragment>
    )
};
