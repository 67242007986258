import React, {Fragment} from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Header = styled.header`
    background-image: url('${props => props.image_url}');
    margin-top: -56px;
    padding: 56px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 300px;
`;

const TitleDiv = styled.div`
    margin-top: 25px;
    padding: 25px;
    background-color: ${props => props.theme.primaryColor};
    opacity: ${props => props.theme.menuOpacity};
    text-align: center;
`;

const TitleH = styled.h1`
    margin-bottom: 10px;
    font-size: 50px;
    color: ${props => props.theme.textColor};
`;

const Hr = styled.hr`
    max-width: 100px;
    border-color: ${props => props.theme.textColor};
`;

const Subhead = styled.span`
    font-size: 24px;
    margin: 10px 0 0;
    color: ${(props) => props.theme.textColor};
`;

const ImageHeader = (props) => {
    const post = props.post;
    const image_url = post.header_image && post.header_image.url ? post.header_image.url : process.env.PUBLIC_URL + "/img/home-bg.jpg";
    const title = post.title ? post.title : "Paul Becotte";
    const makeSubhead = (tagline) => (
        <Fragment>
            <Hr/>
            <Subhead>{tagline}</Subhead>
        </Fragment>
    );
    const subhead = () => {
        if (post.title) {
            if (post.tagline) {
                return makeSubhead(post.tagline);
            }
            return "";
        }
        return makeSubhead("A Python/Devops guy's personal blog")
    };

    return (
        <Header image_url={image_url}>
            <Row>
                <Col lg={{span: 10, offset: 1}}>
                    <TitleDiv>
                        <TitleH>{title}</TitleH>
                        {subhead()}
                    </TitleDiv>
                </Col>
            </Row>
        </Header>
    )
};
export default ImageHeader;
