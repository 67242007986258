import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import {EditorState} from "draft-js";
import styled from "styled-components";
import Prism from "../prism";
import "../prism.css";
import PrismDecorator from "draft-js-prism";
import Editor from "./Editor";


const decorator = new PrismDecorator({
    prism: Prism,
    defaultSyntax: "javascript",
});

const StyledContainer = styled(Container)`
`;


export const EditorDemo = () => {
    const [entry, setEntry] = useState(EditorState.createEmpty(decorator),);
    return (
        <StyledContainer>
            <h1>This is a demo</h1>
            <p>the Select Image button is disabled, but you can paste in a url and hit save</p>
            <Editor uploadImage={() => {}} content={[entry, setEntry]}/>
        </StyledContainer>
    );
};
