import Draft from 'draft-js';
import styled, {createGlobalStyle} from "styled-components";

export const EditorRoot = styled.div`
  min-height: 300px;
  width: 100%;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
`;

export const EditorDiv = styled(Draft.Editor)`
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
`;

export const EditorStyles = createGlobalStyle`
    .RichEditor-root {
      background: #fff;
      border: 1px solid #ddd;
      font-family: 'Georgia', serif;
      font-size: 14px;
      padding: 15px;
    }
    
    .RichEditor-editor {
      border-top: 1px solid #ddd;
      cursor: text;
      font-size: 16px;
      margin-top: 10px;
    }
    
    .RichEditor-editor .public-DraftEditorPlaceholder-root,
    .RichEditor-editor .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 15px;
    }
    
    .RichEditor-editor .public-DraftEditor-content {
      min-height: 100px;
    }
    
    .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
      display: none;
    }
    
    .RichEditor-editor .RichEditor-blockquote {
      border-left: 5px solid #eee;
      color: #666;
      font-family: 'Hoefler Text', 'Georgia', serif;
      font-style: italic;
      margin: 16px 0;
      padding: 10px 20px;
    }
`;
