import React, {createContext, useState} from "react"

const MessageContext = createContext([]);

const MessageProvider = (props) => {
    const messages = useState([]);
    return (
        <MessageContext.Provider value={messages}>
            {props.children}
        </MessageContext.Provider>
    );
};

export {MessageContext, MessageProvider};
