import React, {useContext, useEffect} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button"
import {withRouter} from 'react-router-dom';
import {ApiContext} from "../services/api";
import {MessageContext} from "../services/messages";
import Container from "react-bootstrap/Container";
import Messages from "../outer/Messages";
import decode from "jwt-claims";
import styled from "styled-components";
import Col from "react-bootstrap/Col";

const StyledContainer = styled(Container)`
  margin-top: 56px;
`;

export const Signup = withRouter((props) => {
    const api = useContext(ApiContext);
    const history = props.history;
    const [auth, setAuth] = api.auth;
    const [messages, setMessages] = useContext(MessageContext);
    useEffect(() => {
        if (auth) history.push("/");
    }, [auth, history]);

    const submitForm = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if (data.get("password") !== data.get("confirmpassword")) {
            setMessages(messages + ["Passwords dont match"]);
            return;
        }
        const body = {
            alias: data.get("alias"),
            email: data.get("email"),
            password: data.get("password")
        };
        api.post("/register", JSON.stringify(body), true).then((response) => {
            const token = response.data.access_token;
            const claims = decode(token);
            localStorage.setItem('auth_token', token);
            localStorage.setItem('admin', claims.user_claims && claims.user_claims.roles && "admin" === claims.user_claims.roles[0]);
            localStorage.setItem('statusUser', claims.identity);
            setAuth(true);
        }).catch(() => {
            setMessages(messages.concat("Signup Failed"));
        });
    };

    return (
        <StyledContainer>
            <Messages/>
            <Form onSubmit={submitForm}>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Display Name</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" name="alias"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Email</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="email" name="email"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Password</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="password" name="password"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Confirm Password</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="password" name="confirmpassword"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{span: 4, offset: 2}}>
                        <Button variant="primary" type="submit" block onClick={submitForm}>Submit</Button>
                    </Col>
                    <Col sm={4}>
                        <Button variant="secondary" type="reset" block>Cancel</Button>
                    </Col>
                </Form.Group>
            </Form>
        </StyledContainer>
    );
});
