import {Link, withRouter} from "react-router-dom";
import React, {useContext} from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {ApiContext} from "../services/api";
import styled from "styled-components";

const NavContainer = styled(Container)`
  max-width: 100%;
`;

const StyledNavbar = styled(Navbar)`
  opacity: ${props => props.theme.menuOpacity};
`;

const Menu = withRouter((props) => {
    const api = useContext(ApiContext);
    const auth = api.auth[0];
    const location = props.location.pathname;
    const editEl = () => {
        if (!auth || !(location.startsWith("/blog/post/") || location.startsWith("/blog/edit/"))) return "";
        const parts = location.split("/");
        const slug = parts.pop() || parts.pop();
        const edit = parts.pop() === "edit";
        return edit ? <Nav.Link as={Link} to={`/blog/post/${slug}`}>View</Nav.Link> :
            <Nav.Link as={Link} to={`/blog/edit/${slug}`}>Edit</Nav.Link>;
    };

    return (
        <StyledNavbar bg="dark" variant="dark" fixed="top">
            <NavContainer>
                <Navbar.Brand href="/">Paul Becotte</Navbar.Brand>
                <Nav className="justify-content-end">
                    {editEl()}
                    {auth ? <Nav.Link as={Link} to={"/blog/new"}>New</Nav.Link> : ""}
                    {auth ? <Nav.Link as={Link} to={"/blog/edit"}>Drafts</Nav.Link> : ""}
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About Me</Nav.Link>
                    {!auth ? <Nav.Link as={Link} to="/signup">Sign Up</Nav.Link> : ""}
                    <Nav.Link as={Link} to="/login">{auth ? "Logout" : "Login"}</Nav.Link>
                </Nav>
            </NavContainer>
        </StyledNavbar>
    );
});

export default Menu;
