import React, {Fragment, useContext, useEffect, useState} from "react";
import {ApiContext} from "../services/api";
import PostIndex from "./PostIndex";
import PostEdit from "./PostEdit";
import PostDetail from "./PostDetail";
import Container from "react-bootstrap/Container";
import ImageHeader from "./ImageHeader";
import Messages from "../outer/Messages";
import {convertFromRaw, EditorState} from "draft-js";
import styled from "styled-components";
import Prism from "../prism";
import "../prism.css";
import PrismDecorator from "draft-js-prism";


const decorator = new PrismDecorator({
    prism: Prism,
    defaultSyntax: "javascript",
});

const StyledContainer = styled(Container)`
`;

const emptyEntry = () => {
    return {
        title: "",
        slug: "",
        tagline: "",
        content: EditorState.createEmpty(decorator),
        published: false,
        timestamp: new Date(),
        header_image: {
            url: "",
        },
        author: {
            alias: ""
        },
    }
};

export const PostContainer = (props) => {
    let core;
    const slug = props.slug || "";
    const api = useContext(ApiContext);
    const [entry, setEntry] = useState(emptyEntry);

    useEffect(() => {
        if (slug === "") {
            setEntry(emptyEntry);
        } else {
            api.get(`/blog/post/${slug}`)
                .then((response) => {
                    const baseContentState = convertFromRaw(JSON.parse(response.data.post.content));
                    const editorState = EditorState.createWithContent(baseContentState, decorator);
                    setEntry({...response.data.post, ...{content: editorState}});
                });
        }
    }, [slug, api]);
    if (props.edit) {
        core = <PostEdit entry={entry} setEntry={setEntry}/>;
    } else if (slug === "") {
        core = <PostIndex drafts={props.drafts}/>;
    } else {
        core = <PostDetail entry={entry}/>;
    }
    return (
        <Fragment>
            {props.edit ? "" : <ImageHeader post={entry}/>}
            <StyledContainer>
                <Messages/>
                {core}
            </StyledContainer>
        </Fragment>
    );
};
