import React, {createContext, useContext, useEffect, useState} from "react"
import {MessageContext} from "./messages";
import {apiUrl} from "../constants";
import {withRouter} from "react-router-dom";

const ApiContext = createContext({});

const ApiProvider = withRouter((props) => {
    const [messages, setMessages] = useContext(MessageContext);
    const [auth, setAuth] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('auth_token')) setAuth(true);
    }, [setAuth]);

    const handleAuth = () => {
        const current = props.location;
        localStorage.removeItem('auth_token');
        setAuth(false);
        props.history.push(`/login?redirect=${current}`);
    };

    const status = (response) => {
        if (response.status >= 200 && response.status < 400) {
            return Promise.resolve(response).then(r => r.json()).then((data) => {
                if (data && data.messages && data.messages.length > 0) {
                    setMessages(messages.concat(data.messages));
                }
                return Promise.resolve(data);
            })
        }
        if (response.status === 401) {
            handleAuth();
            return Promise.resolve({});
        }
        return Promise.resolve(response)
            .then(r => r.json())
            .then((json) => {
                if (json.error && json.error.message) {
                    setMessages(messages.concat([JSON.stringify(json.error.message)]));
                    return Promise.reject(json.error.message);
                }
                return Promise.reject(response.statusText);
            }, () => Promise.reject(response.statusText))
    };

    const handleResponse = (promise) => {
        return promise.then(status)
    };

    const api = {
        auth: [auth, setAuth],
        get: async (url) => {
            return handleResponse(fetch(`${apiUrl}${url}`, {
                headers: head(),
                redirect: "manual",
            }));
        },
        put: async (url, body, json) => {
            const headers = head();
            if (json) {
                headers["Content-Type"] = "application/json";
            }
            return handleResponse(fetch(`${apiUrl}${url}`, {
                method: 'PUT',
                body: body,
                headers: headers,
                redirect: "manual",
            }));
        },
        post: async (url, body, json) => {
            const headers = head();
            if (json) {
                headers["Content-Type"] = "application/json";
            }
            return handleResponse(fetch(`${apiUrl}${url}`, {
                method: 'POST',
                body: body,
                headers: headers,
                redirect: "manual",
            }));
        }
    };

    return (
        <ApiContext.Provider value={api}>
            {props.children}
        </ApiContext.Provider>
    )
});

const head = () => {
    const authToken = localStorage.getItem('auth_token');

    return authToken ? {
        'Authorization': `Bearer ${authToken}`,
    } : {};
};

export {ApiContext, ApiProvider};
