import React from "react";
import {EditorState, Modifier} from "draft-js";
import CodeUtils from "draft-js-code";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"

import {AddImage} from "./Image";

const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
];

export const BlockStyleControls = (props) => {
    const {editorState, setEditorState, uploadImage} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    const onToggle = (e, type) => {
        e.preventDefault();
        props.onToggle(type.style);
    };
    return (
        <ButtonGroup size={"sm"} className="mb-3">
            {BLOCK_TYPES.map((type) =>
                <Button onMouseDown={(e) => onToggle(e, type)} active={type.style === blockType} key={type.label}>
                    {type.label}
                </Button>
            )}
            <AddImage editorState={editorState} setEditorState={setEditorState} uploadImage={uploadImage}/>
        </ButtonGroup>
    );
};

const INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
];

const getSyntax = (block) => {
    if (block.getData) {
        return block.getData().get('syntax');
    }
    return null;
};

const SyntaxBlock = (props) => {
    const {editorState, updateEditorState} = props;
    if (!CodeUtils.hasSelectionInBlock(editorState)) return "";
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);
    const currentSyntax = getSyntax(currentBlock);
    const onChange = (e) => {
        const newContent = Modifier.mergeBlockData(contentState, selection, {"syntax": e.target.value});
        updateEditorState(EditorState.push(editorState, newContent, 'change-block-data'));
    };
    if (!currentSyntax) {
        onChange({target: {value: 'javascript'}});
    }
    return (
        <Form.Control as={"select"} onChange={onChange} value={currentSyntax}>
            <option value="css">CSS</option>
            <option value="javascript">Javascript</option>
            <option value="python">Python</option>
        </Form.Control>
    );
};

export const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    const onToggle = (e, type) => {
        e.preventDefault();
        props.onToggle(type.style);
    };
    return (
        <ButtonGroup size={"sm"} className="mb-3">
            {INLINE_STYLES.map((type) =>
                <Button onMouseDown={(e) => onToggle(e, type)} active={currentStyle.has(type.style)} key={type.label}>
                    {type.label}
                </Button>
            )}
            <SyntaxBlock editorState={props.editorState} updateEditorState={props.updateEditorState}/>
        </ButtonGroup>
    );
};
