import React, {useContext, useRef} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ApiContext} from "../services/api";
import {withRouter} from 'react-router-dom';
import styled from "styled-components";
import {convertToRaw} from "draft-js";
import Editor from '../editor/Editor';

const StyledForm = styled.form`
    margin-top: 25px;
`;

const StyledFormGroup = styled(Form.Group)`
  border: 1px solid #ced4da;
  margin: 0 0 25px 0;
`;

const PostEdit = withRouter((props) => {
    const api = useContext(ApiContext);
    const entry = props.entry;
    const updateEntry = props.setEntry;

    const uploadImage = (image) => {
        const form = new FormData();
        form.append('file', image);
        return api.post("/blog/image", form).then((response) => response.data.image);
    };

    const submitForm = (event) => {
        event.preventDefault();
        const submit = JSON.stringify({
            post: {
                ...entry,
                ...{content: JSON.stringify(convertToRaw(entry.content.getCurrentContent()))}
            }
        });
        if (props.entry.slug !== "") {
            api.put(`/blog/post/${props.entry.slug}`, submit, true).then();
        } else {
            api.post(`/blog/post`, submit, true).then((response) => {
                props.history.push(`/blog/post/${response.data.post.slug}`);
            });
        }
    };

    const updateContent = (content) => updateEntry({...entry, ...{content: content}});
    const inputFile = useRef(null);
    return (
        <StyledForm>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Title</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" defaultValue={entry.title}
                                  onChange={(event) => updateEntry({...entry, ...{title: event.target.value}})}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Tagline</Form.Label>
                <Col sm={10}>
                    <Form.Control type="text" defaultValue={entry.tagline}
                                  onChange={(event) => updateEntry({...entry, ...{tagline: event.target.value}})}/>
                </Col>
            </Form.Group>
            <StyledFormGroup as={Row}>
                <Editor uploadImage={uploadImage} content={[props.entry.content, updateContent]}/>
            </StyledFormGroup>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Header Image</Form.Label>
                <Col sm={{span: 4}}>
                    <input id="myInput" type="file" ref={inputFile} style={{display: 'none'}} onChange={(event) => {
                        uploadImage(event.target.files[0]).then((image) => updateEntry({...entry, ...{header_image: image}}))
                    }}/>
                    <Button variant="primary" type="button" block onClick={() => inputFile.current.click()}>Select An
                        Image</Button>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Published</Form.Label>
                <Col sm={10}>
                    <Form.Check type="checkbox" checked={entry.published} onChange={(event) => {
                        updateEntry({...entry, ...{published: event.target.checked}});
                    }}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col sm={{span: 4, offset: 2}}>
                    <Button variant="primary" type="submit" block onClick={submitForm}>Submit</Button>
                </Col>
                <Col sm={4}>
                    <Button variant="secondary" type="reset" block>Cancel</Button>
                </Col>
            </Form.Group>
        </StyledForm>
    );
});

export default PostEdit;
