import React, {useContext} from "react";
import Alert from "react-bootstrap/Alert";
import {MessageContext} from "../services/messages";

function Messages() {
    const [messages, setMessages] = useContext(MessageContext);
    if (messages.length === 0) {
        return <></>;
    }
    const clearMessage = (message) => {
        setMessages(messages.filter((value) => {
            return value !== message;
        }));
    };

    const els = messages.map((message, idx) => (
        <Alert key={idx} variant="danger" onClose={() => clearMessage(message)} dismissible>
            {message}
        </Alert>
    ));
    return <ul>{els}</ul>;
}

export default Messages;
