import React, {Fragment} from "react";
import {createGlobalStyle, ThemeProvider} from "styled-components";

export const theme = {
    primaryColor: "#703636",
    secondaryColor: "maroon",
    textColor: "white",
    menuOpacity: 1,
};

const GlobalStyle = createGlobalStyle`
    .fas {
        color: ${(props) => props.theme.primaryColor};
    }

    .editorstyle {
        height: auto;
        min-height: 300px;
    }
    
    .bg-dark {
        background-color: ${(props) => props.theme.primaryColor}!important;
    }
    
    .navbar-dark .navbar-brand {
        color: ${props => props.theme.textColor};
        font-size: 1.5rem;
    }
    
    .navbar-dark .navbar-nav .nav-link {
        color: ${props => props.theme.textColor};
    }
`;

const AddTheme = WrappedComponent => props => (
    <ThemeProvider theme={theme}>
        <Fragment>
            <GlobalStyle/>
            <WrappedComponent {...props}/>
        </Fragment>
    </ThemeProvider>
);
export default AddTheme;
