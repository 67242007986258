import React, {useState} from 'react';
import Draft, {EditorState, Modifier} from 'draft-js';
import CodeUtils from 'draft-js-code';
import Prism from "../prism";
import PrismDecorator from "draft-js-prism";

import {BlockStyleControls, InlineStyleControls} from "./Controls";
import {EditorDiv, EditorRoot, EditorStyles} from "./Styles";
import {mediaBlockRenderer} from "./Image";
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";


export default (props) => {
    const [editorState, setEditorState] = props.content;
    const editor = React.useRef(null);
    const [readOnly, setReadOnly] = useState(false);
    const onChange = (newState) => {
        const decorator = new PrismDecorator({
            prism: Prism,
            defaultSyntax: "javascript",
        });
        setEditorState(EditorState.set(newState, {decorator}));
    };
    const handleKeyCommand = (command, editorState) => {
        let newState;
        if (CodeUtils.hasSelectionInBlock(editorState)) {
            newState = CodeUtils.handleKeyCommand(editorState, command);
        }
        if (!newState) {
            newState = Draft.RichUtils.handleKeyCommand(editorState, command);
        }
        if (newState) {
            onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
            onChange(CodeUtils.onTab(e, editorState));
            return;
        }
        if (e.keyCode === 13 /* RETURN */) {
            if (CodeUtils.hasSelectionInBlock(editorState)) {
                // This prevents splitting the block inside a code example
                onChange(CodeUtils.handleReturn(e, editorState));
                return;
            }
        }
        if (CodeUtils.hasSelectionInBlock(editorState)) {
            const command = CodeUtils.getKeyBinding(e);
            if (command) return command;
        }
        return Draft.getDefaultKeyBinding(e);
    };

    const handlePastedText = (pastedText) => {
        if (CodeUtils.hasSelectionInBlock(editorState)) {
            const newContent = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                pastedText,
            );
            onChange(EditorState.push(
                editorState,
                newContent,
                'insert-characters'
            ));
            return true;
        }
        return false;
    };

    const toggleBlockType = (blockType) => {
        let state = editorState;
        if (blockType === "code-block" && CodeUtils.hasSelectionInBlock(editorState)) {
            const content = state.getCurrentContent();
            const selection = state.getSelection();
            const split = Modifier.splitBlock(content, selection);
            state = EditorState.push(state, split, 'split-block');
        }
        onChange(Draft.RichUtils.toggleBlockType(state, blockType));
    };

    const toggleInlineStyle = (inlineStyle) => {
        onChange(Draft.RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const updateImage = (key, imageData) => {
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(key);
        const newContent = contentState.mergeEntityData(block.getEntityAt(0), {...imageData});
        const updatedState = EditorState.push(editorState, newContent, 'change-block-data');
        onChange(updatedState);
    };

    const getBlockStyle = (block) => {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            case 'code-block':
                return 'language-';
            default:
                return null;
        }
    };
    return (
        <EditorRoot className="RichEditor-root">
            <EditorStyles/>
            <Row>
                <Col sm={12}>
                    <BlockStyleControls
                        editorState={editorState}
                        setEditorState={setEditorState}
                        uploadImage={props.uploadImage}
                        onToggle={toggleBlockType}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <InlineStyleControls
                        editorState={editorState}
                        updateEditorState={onChange}
                        onToggle={toggleInlineStyle}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} className="RichEditor-editor">
                    <EditorDiv
                        ref={editor}
                        editorState={editorState}
                        onChange={onChange}
                        blockRendererFn={mediaBlockRenderer(updateImage, props.uploadImage, setReadOnly)}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={mapKeyToEditorCommand}
                        blockStyleFn={getBlockStyle}
                        spellCheck={true}
                        handlePastedText={handlePastedText}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>
        </EditorRoot>
    )
};
