import React, {Fragment} from "react";
import Comment from "./Comment";
import {EditorDiv} from "../editor/Styles";
import {mediaBlockRenderer} from "../editor/Image";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const DateDiv = styled.p`
  padding-top: 25px;
  color: #5a6268;
  font-size: 19px;
`;

const AuthorDiv = styled.p`
  color: #5a6268;;
  font-size: 19px;
`;

const BigRule = styled.hr`
  border-width: 5px;
  margin-bottom: 40px;
`;

const PostDetail = (props) => {
    const entry = props.entry;
    if (!entry) return <Spinner animation="border" role="status"/>;
    const getBlockStyle = (block) => {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            case 'code-block':
                return 'language-';
            default:
                return null;
        }
    };
    return (
        <Fragment>
            <DateDiv>Created {new Date(entry.timestamp).toDateString()}</DateDiv>
            <AuthorDiv>Author {entry.author.alias}</AuthorDiv>
            <BigRule/>
            <div>
                <EditorDiv
                    editorState={entry.content}
                    blockStyleFn={getBlockStyle}
                    blockRendererFn={mediaBlockRenderer()}
                    readOnly={true}
                />
                <hr/>
                <h3>Comments</h3>
                {entry.slug ? <Comment slug={entry.slug}/> : ""}
                <hr/>
            </div>
        </Fragment>
    );
};

export default PostDetail;
