import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button"
import {withRouter} from 'react-router-dom';
import {ApiContext} from "../services/api";
import {MessageContext} from "../services/messages";
import Container from "react-bootstrap/Container";
import Messages from "../outer/Messages";
import decode from "jwt-claims";
import styled from "styled-components";
import Col from "react-bootstrap/Col";

const StyledContainer = styled(Container)`
  margin-top: 56px;
`;

export const Login = withRouter((props) => {
    const api = useContext(ApiContext);
    const [auth, setAuth] = api.auth;
    const [messages, setMessages] = useContext(MessageContext);
    const submitForm = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const body = {
            email: data.get("email"),
            password: data.get("password")
        };
        api.post("/login", JSON.stringify(body), true).then((response) => {
            const token = response.data.access_token;
            const claims = decode(token);
            localStorage.setItem('auth_token', token);
            localStorage.setItem('admin', claims.user_claims && claims.user_claims.roles && "admin" === claims.user_claims.roles[0]);
            localStorage.setItem('statusUser', claims.identity);
            setAuth(true);
            if (props.location.search.redirect) {
                props.history.push(props.location.search.redirect)
            } else {
                props.history.push("/")
            }
        }).catch(() => {
            setMessages(messages.concat("Login Failed"));
        });
    };

    const logout = () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('admin');
        localStorage.removeItem('statusUser');
        setAuth(false);
        props.history.push("/")
    };

    let form;
    if (auth !== true) {
        form = (
            <Form onSubmit={submitForm}>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Username</Form.Label>
                    <Col sm={4}>
                        <Form.Control type="email" name="email"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>Password</Form.Label>
                    <Col sm={4}>
                        <Form.Control type="password" name="password"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col sm={{offset: 2, span: 4}}>
                        <Button type="submit">Login</Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    } else {
        form = (
            <Col sm={{offset: 2, span: 4}}>
                <Button onClick={logout}>Logout</Button>
            </Col>
        );
    }

    return (
        <StyledContainer>
            <Messages/>
            {form}
        </StyledContainer>
    );
});
